<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V3"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >

          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bem Vindo
          </b-card-title>

          
          <!-- form -->
            <b-form
              class="auth-login-form mt-2"
              v-on:submit.prevent="Login"
            >
             <div v-if="this.bloquearLogin == false">
              <b-card-text class="mb-2">
              Por favor entre com seu Login e Senha
              </b-card-text>

              <!-- email -->
              <b-form-group
                label="Usuario"
              >
                   <b-form-input
                    id="login-usuario"
                    name="login-usuario"
                    v-model="login"
                    placeholder=""
                    required
                  />
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Senha</label>
                </div>
                  <b-input-group
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                      required
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
              </b-form-group>
              <div v-if="this.usuario != '' && this.usuario.jsessionid == null" class="alert alert-danger">
                {{this.statusMessage}}
              </div>
              <!-- submit buttons -->
              <div class="DivBotaoLadoLado" v-if="this.conectando == false" >
              <b-button  @click="Login()"
                variant="primary"
              >
                Entrar
              </b-button>
              </div>

            </div>
             
              <b-button v-if="this.conectando == true"
                variant="outline-primary"
                disabled
              >
              <b-spinner small/>
                Conectando...
              </b-button>

              

            </b-form>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      conectando: false,
      bloquearLogin: false,
      password: '',
      login: '',
      sideImg: require('@/assets/images/logo/LogoVapza.png'),
      usuario: '',
      usuarioAcessoMenu: [],
      statusMessage: 'Login/ Senha inválido!',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/LogoVapza.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    // if (this.$session.exists('clearCache') === false) {
    //   this.$session.set('clearCache', 'ok')
    //   window.location.reload(true)
    //   window.location.href = window.location.href
    // }

    this.bloquearLogin = true;
    let LoginPorRota = 'N'

    // Verifica se Session Usuario Exite e Elimina
    if (this.$session.exists('usuario')) {
      this.$session.remove('usuario')
      // this.$session.remove('clearCache')
    }

    if (this.$route.query.nunota != null && this.$route.query.agesenha != null) {
      LoginPorRota = 'S'
      this.LoginAgendamento(this.$route.query)
    }else{
      if (this.$route.query.tmid != null && this.$route.query.tkn != null) {
           LoginPorRota = 'S'
           this.LoginTermoAutorizacaoEntrada(this.$route.query)
      }
      else{
         if (this.$route.query.p1 != null && this.$route.query.p2 != null) {
             LoginPorRota = 'S'
             this.LoginSSID(this.$route.query)
            }
      }
    }

    if(LoginPorRota == 'N'){
      this.bloquearLogin = false;
    }
  },
  methods: {
    async Login() {
      let notification = ''
      this.conectando = true
      this.usuario = ''
      const Login = { headers: { 'Access-Control-Allow-Origin': '*' }, NOMEUSU: this.login, SENHA: this.password }
      const URLpost = '/Login/LogarPortalSky'
      await apiPortalSky.post(URLpost, Login).then(response => {
        this.usuario = response.data
        this.conectando = false
        if (response.status === 200 && this.usuario.jsessionid != null && this.usuario.statusMessage == null) {
          this.Redirect('/')
        } else if (this.usuario.statusMessage !== null && this.usuario.statusMessage !== '') {
          this.statusMessage = this.usuario.statusMessage
        }
      }).catch(error => {
        this.conectando = false
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })
    },
    async LoginSSID(params) {
      let notification = ''
      this.conectando = true
      this.usuario = ''
      const Login = { CODUSU: params.p1, SEQACESSO: params.p2, DATA: params.p3 }
      const URLpost = '/Login/LogarPortalSkySSID'
      await apiPortalSky.post(URLpost, Login).then(response => {
        this.usuario = response.data
        this.conectando = false
        if (response.status === 200 && this.usuario.jsessionid != null && this.usuario.statusMessage == null) {
          this.Redirect(params.p4)
        } else if (this.usuario.statusMessage !== null && this.usuario.statusMessage !== '') {
          this.statusMessage = this.usuario.statusMessage
        }
      }).catch(error => {
        this.conectando = false
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        this.$router.push('/')
      })
    },
    async LoginAgendamento(params) {
      let notification = ''
      this.conectando = true
      this.usuario = ''
      const Login = { NUNOTA: Number(params.nunota), AGESENHA: params.agesenha }
      const URL = '/Login/LogarPortalSkyAgendamento'

      await apiPortalSky.post(URL, Login).then(response => {
        this.usuario = response.data
        this.conectando = false
        if (response.status === 200) {
          this.$session.set('usuario', this.usuario)
          this.$router.push({ name: 'cadastroAgendamentoEntrega', params: { nunota: Number(params.nunota), edicaoAdm: false } })
        }
      }).catch(error => {
        this.conectando = false
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        this.$router.push('/')
      })
    },
    async LoginTermoAutorizacaoEntrada(params) {
      let notification = ''
      this.conectando = true
      this.usuario = ''
      const Login = { AUTORIZACAOENTRADAID: Number(params.tmid), TOKEN: params.tkn }
      const URL = '/Login/LogarPortalSkyTermoAutorizacaoEntrada'

      await apiPortalSky.post(URL, Login).then(response => {
        this.usuario = response.data
        this.conectando = false
        if (response.status === 200) {
          this.$session.set('usuario', this.usuario)
          this.$router.push({ name: 'TermoAutorizacaoEntrada', params: { AUTORIZACAOENTRADAID: Number(params.tmid), edicaoAdm: false } })
        }
      }).catch(error => {
        this.conectando = false
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        this.$router.push('/')
      })
    },
    async Redirect(router) {

      this.usuario.filtrosTela = []
      this.$session.set('usuario', this.usuario)
      // console.log(this.$session.get('usuario')) //Exemplo Pegar Session
      // console.log(this.$session.exists('usuario')) //Exemplo Validar Session
      // console.log(this.$session.remove('usuario')) //Exemplo Excluir Session
      // console.log(this.$session.clear('usuario')) //Exemplo Excluir Todas Session com esse ID

      let routerFinal = "/";

      if(router !== ""){
        if(router != "/"){
           routerFinal = "../../../" + router;
        }else{
           routerFinal = "../home";
        }
      }

      this.$router.push(routerFinal)


    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
